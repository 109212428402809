//======================================================================================================
// Style Home
//======================================================================================================
.home {

    .container {
        &--flex {
            display: flex;
            column-gap: 30px;
        }
    }

    &__title {
        margin: 0 0 30px 0;
        padding: 0;
        border: 0;
        text-align: left;
        font-size: $font-size--2;
        color: $color-text;

        &--white {
            color: $color-white;
        }

        &--second {
            color: $color-second;
        }
    }

    &__buttons {
        display: flex;
        align-content: center;
        justify-content: flex-start;
        gap: 15px;
        margin: 30px 0 0 0;

        .button {
            min-width: 170px;
            margin: 0;
            padding: 12px 30px;
        }
        
    }

    .button--white {
        background-color: $color-white;
        border-color: $color-white;
        color: $color-text;

        &:hover, &:focus {
            background-color: $color-bg--transparent;
            border-color: $color-white;
            color: $color-white;
        }
    }
  
  
    //======================================================================================================
    // SlideShow & Access
    //======================================================================================================
    &__slider-access {
        position: relative;
        display: block;
        background-color: $color-bg--neutral;

        &__container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            pointer-events: none;
        }
    }

    // SlideShow
    .home-slideshow {
        position: relative;
        overflow: hidden;
        width: 100%;
        margin-top: -38px;
        padding-top: 38px;
        padding-right: calc(((100% - 1170px) / 2) + 285px);
  
        .swiper-container {
            height: 650px;
            width: 100%;
            z-index: inherit;
    
            .swiper-slide {
                img {
                    position: absolute;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    object-position: top;
                }
            }
  
            .container--pagination {
                position: absolute;
                display: flex;
                align-items: center;
                right: 135px;
                left: auto;
                top: auto;
                bottom: 35px;
                height: 50px;
                width: 130px;
                pointer-events: none;

                .swiper-button {
                    pointer-events: auto;
                    top: 0;
                    width: 50px;
                    height: 50px;
                    margin: 0;
                    padding: 0;
                    background-color: $color-bg--transparent;
                    border: 0;

                    &::after {
                        display: none;
                    }

                    svg {
                        width: 50px;
                        height: 50px;
                        margin: 0;
                        fill: $color-white;
                        transition: all ease-in-out $duration;
                    }

                    &:hover, &:focus {
                        svg {
                            fill: $color-main;
                            transition: all ease-in-out $duration;
                        }
                    }
                }

                .swiper-button-prev {
                    left: 0;
                }

                .swiper-button-next {
                    right: 0;
                }
            }
        }
  
        .slideshow {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            .container--slideshow {
                position: relative;
                display: flex;
                justify-content: center;
                height: 100%;
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                box-sizing: border-box;
                position: absolute;
                width: 100%;
                height: auto;
                min-height: 120px;
                bottom: 0;
                padding: 30px 315px 30px calc(((100vw - 1170px) / 2));
                background-color: $color-black--rgba;
                border-radius: $border-radius;
                text-align: left;
                color: $color-white;
            }

            &__title {
                font-family: $font-family--heading;
                font-weight: $font-weight--heading;
                line-height: $line-height--heading;
                font-size: $font-size--3;
            }

            &__description {
                margin-top: 10px;
            }
        }

        .video-container {
            height: 650px;
            width: 100%;
    
            .slideshow {
                display: inherit;
                position: relative;
                width: 100%;
                height: 100%;

                video,
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    object-position: top;
                }

                img {
                    display: none;
                }
            }
        }
    }
  
  
    // Access (admin-area-1)
    .access {
        position: relative;
        padding: 70px 0 15px;
        width: 400px;
        margin-right: -15px;
        pointer-events: auto;
  
        &__list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
                margin: 15px;
            }
        }
  
        &__link {
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            border-radius: $border-radius;
            font-size: $font-size--text_small;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            line-height: 1.1;
            box-shadow: $shadow;
  
            .access__image {
                width: 170px;
                height: 108px;
                margin-bottom: 62px;
                background-color: $color-white;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
  
            &:hover, &:focus {
                .access__content {
                    height: 100%;
                    transition: height ease-in-out $duration;
                }

            }
        }

        &__content {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 62px;
            padding: 15px;
            background-color: $color-main;
            text-align: center;
            color: $color-second;
            line-height: 1.3;
            transition: height ease-in-out $duration;
        }
  
    }


    //======================================================================================================
    // News (posts)
    //======================================================================================================
    .news {
        position: relative;
        padding: 70px 0;
        width: 770px;

        &__container {
            position: relative;
        }

        .swiper {
            &-wrapper {
                display: flex;
                align-items: stretch;
            }

            &-slide {
                overflow: hidden;
                width: 370px;
                margin: 0 30px 0 0;
                box-shadow: $shadow;
                transition: box-shadow $duration ease-in-out;

                &:hover, &:focus {
                    box-shadow: none;
                    transition: box-shadow $duration ease-in-out;

                    .news__infos {
                        background-color: $color-bg--neutral;
                        transition: background-color $duration ease-in-out;
                    }
                }
            }
    
            &-container {
                padding: 15px;
                margin: -15px;
            }
        }

        &__navigation {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: space-between;
            right: 0;
            left: auto;
            top: 0;
            bottom: auto;
            height: 50px;
            width: 130px;
            pointer-events: none;

            &--no-slide {
                display: none;
            }

            .swiper-button {
                pointer-events: auto;
                top: 0;
                width: 50px;
                height: 50px;
                margin: 0;
                padding: 0;
                background-color: $color-bg--transparent;
                border: 0;

                &::after {
                    display: none;
                }

                svg {
                    width: 50px;
                    height: 50px;
                    margin: 0;
                    fill: $color-text;
                    transition: all ease-in-out $duration;
                }

                &:hover, &:focus {
                    svg {
                        fill: $color-main;
                        transition: all ease-in-out $duration;
                    }
                }
            }

            .swiper-button-prev {
                left: 0;
            }

            .swiper-button-next {
                right: 0;
            }
        }

        &__link {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: stretch;
            overflow: hidden;
            width: 100%;
            border-radius: $border-radius;
            color: $color-text;

            &:hover, &:focus {
                color: $color-text;
                box-shadow: $shadow--hover;

                img {
                    transform: scale(1.1);
                    transition: transform ease-in-out $duration;
                }
            }

        }

        &__content {
            overflow: hidden;
            line-height: 0;
            height: 215px;

            &--notimg {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) no-repeat $color-bg--image;
                background-position: center;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top center;
                transform: scale(1);
                transition: transform ease-in-out $duration;
            }
        }

        &__infos {
            box-sizing: border-box;
            height: 225px;
            padding: 30px;
            background-color: $color-white;
            text-align: left;
            transition: background-color $duration ease-in-out;

            &__title {
                margin: 0;
                font-size: $font-size--3;
            }

            &__introduction {
                margin-top: 10px;
            }
        }
  
    }
  
  
    //======================================================================================================
    // Docs (Kiosque)
    //======================================================================================================
    .kiosque {
        position: relative;
        padding: 70px 0;
        width: 370px;


        &__item {
            position: relative;
            display: block;
        }

        &__content {
            position: absolute;
            bottom: 45px;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 270px;
            min-height: 205px;
            padding: 50px 30px;
            background-color: $color-white;
            box-shadow: $shadow;
        }

        &__title {
            margin: 0 0 20px 0;
            font-size: $font-size--4;
        }

        &__image {
            display: block;
            background-color: $color-main;
            width: 297px;
            height: 440px;
            overflow: hidden;
            border-radius: $border-radius;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }

            &--no-image {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-bg--image;

                svg {
                    width: 40%;
                }
            }
        }

        &__filesize {
            text-transform: uppercase;
        }

        &__buttons {
            position: absolute;
            top: -25px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
        }

        &__btn {
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            text-decoration: none;
            margin: 0 20px 0 0;

            &:last-child {
                margin-right: 0;
            }

            svg {
                width: 50px;
                height: 50px;
                margin-right: 0;
                padding: 5px;
            }

            &:hover, &:focus {
                svg {
                    background-color: $color-dark;
                    fill: $color-white;
                }
            }
        }


    }


    //======================================================================================================
    // Events
    //======================================================================================================
    .events {
        position: relative;
        padding: 70px 0;
        background-color: $color-dark;

        &__container {
            position: relative;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
        }

        &__item {
            position: relative;
            display: flex;
            flex: 1 1 47%;
            margin-left: 50px;
            margin-bottom: 30px;
            padding: 15px;
            background-color: $color-white;
            border-radius: $border-radius;
            transition: all ease-in-out $duration;

            &:first-child, &:nth-child(3) {
                margin-left: 20px;
            }

            &:nth-child(3), &:nth-child(4) {
                margin-bottom: 0;
            }

            &:hover, &:focus {
                color: $color-second;
                background-color: $color-main;
                transition: all ease-in-out $duration;

                .events__date {
                    background-color: $color-white;
                    color: $color-text;
                    transition: all ease-in-out $duration;

                    svg {
                        fill: $color-text;
                    }
                }

                .events__info__taxonomy {
                    color: $color-second;
                }
            }
        }

        &__link {
            display: flex;
            align-items: stretch;
            color: inherit;
            transition: none;
        }

        &__date {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-left: -35px;
            padding: 15px 20px;
            background-color: $color-main;
            border-radius: $border-radius;
            color: $color-second;
            height: 175px;
            transition: all ease-in-out $duration;

            p {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 50px;
            }

            &__day {
                font-size: 2.188rem;
                line-height: 1.1;
                font-weight: $font-weight-bold;
            }

            &__month {
                margin-top: 5px;
                font-size: 1rem;
                font-weight: $font-weight-medium;
                line-height: 1.1;
                text-transform: uppercase;
            }

            svg {
                position: relative;
                width: 50px;
                height: 25px;
                fill: $color-second;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            padding: 10px 10px 10px 35px;

            &__taxonomy {
                margin-bottom: 10px;
                font-size: $font-size--text-small;
                font-weight: $font-weight-bold;
                color: $color-text;
                text-transform: $btn-text-transform;
                transition: all ease-in-out $duration;
            }

            &__title {
                margin: 0;
            }
        }

        &__buttons {
            justify-content: flex-end;

            .button {
                &:hover, &:focus {
                    background-color: $color-white;
                    border-color: $color-white;
                    color: $color-text;
                }

                &--secondary {
                    margin-right: 0;
                    color: $color-white;
    
                    &:hover, &:focus {
                        color: $color-text;
                    }
                }

            }
        }

    }


    //======================================================================================================
    // Social Wall
    //======================================================================================================
    .social-wall {
        position: relative;
        padding: 70px 0;
        background-color: $color-main;

        .ff-stream {
            min-height: 440px !important;
        }

        #ff-stream-1, #ff-stream-1 .ff-popup, #ff-stream-1 .ff-search input {
            padding: 0;
            background-color: $color-main !important;
        }
        #ff-stream-1 .ff-header {
            display: none;
            z-index: 0;
        }
        .ff-stream-wrapper {
            margin: 0;
        }
        #ff-stream-1 .ff-filter:hover, 
        #ff-stream-1 .ff-filter.ff-filter--active, 
        #ff-stream-1 .ff-moderation-button, 
        #ff-stream-1 .ff-loadmore-wrapper .ff-btn, 
        #ff-stream-1 .ff-square:nth-child(1) {
            min-width: 380px;
            margin: 30px 15px 0 15px;
            padding: 12px 30px;
            border: $btn-border-width $btn-border-style $color-white;
            border-radius: $btn-border-radius;
            background-color: $color-white;
            color: $color-text;
            font-size: $btn-font-size;
            font-weight: $btn-font-weight;
            font-family: $btn-font-family;
            line-height: $btn-line-height;
            text-transform: $btn-text-transform;
            transition: all ease-in-out $duration;

            &:hover, &:focus {
                background-color: $color-dark;
                border-color: $color-dark;
                color: $color-white;
                transition: all ease-in-out $duration;
            }
        }
        .ff-pinterest .ff-icon-inner {
            background-color: #BD081C;
        }
        .ff-facebook .ff-icon-inner {
            background-color: #1877F2;
        }
        .ff-twitter .ff-icon-inner {
            background-color: #1DA1F2;
        }
        .ff-instagram .ff-icon-inner {
            background-color: #E4405F;
        }
        .ff-youtube .ff-icon-inner {
            background-color: #FF0000;
        }

        .ff-item a {
            color: $color-text;
        }

        .ff-upic-round .ff-img-holder:first-child, .ff-upic-round .ff-img-holder:first-child img,
        .ff-upic-round .picture-item__inner, .ff-upic-round .picture-item__inner:before, .ff-sc-label1 .ff-icon-inner {
            border-radius: $border-radius;
        }

        #ff-stream-1 {
            margin: 0 -15px;
        }
    }
  

    //======================================================================================================
    // Zoom (admin-area-2)
    //======================================================================================================
    .zoom {
        position: relative;
        padding: 70px 0;
        background-color: $color-bg--neutral;

        &__list {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            column-gap: 30px;
        }

        &__item {
            position: relative;
            flex: 1 1 0;
            padding-bottom: 60px;
            border-radius: $border-radius;

            &:first-child {                
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &__link, &__block {
            display: block;
            position: relative;
            background-color: $color-main;
            color: $color-second;
            text-decoration: none;
        }

        &__link {
            &:hover, &:focus {
                .zoom {
                    &__image img {
                        transform: scale(1.1);
                        transition: transform ease-in-out $duration;
                    }

                    &__content {
                        background-color: $color-dark;
                        color: $color-white;
                        transition: all ease-in-out $duration;
                    }
                }
            }
        }

        &__image {
            height: 150px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                //object-position: top;
                transform: scale(1);
                transition: transform ease-in-out $duration;
            }
        }

        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -60px;
            margin: 0 20px;
            padding: 30px;
            background-color: $color-main;
            color: $color-second;
            z-index: 1;
            transition: all ease-in-out $duration;
            box-shadow: $shadow;
        }

        &__title {
            font-family: $font-family--heading;
            font-size: $font-size--4;
            font-weight: $font-weight--heading;
        }

    }


} 
  
  
//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .home {

        // Style home 
        &__buttons {
            gap: 10px;

            .button {
                min-width: 140px;
            }
        }    

        // Slideshow
        .home-slideshow {
            padding-right: calc(((100% - 940px) / 2) + 230px);

            .slideshow {
                &__content {
                    padding: 30px 250px 30px calc(((100vw - 940px) / 2));
                }
            }

            .swiper-container .container--pagination {
                right: 93px;
                bottom: 55px;
            }

            .swiper-container {
                height: 540px;
            }
        }


        // Access (admin-area-1)
        .access {
            width: 320px;
            margin-right: -10px;

            &__list li {
                margin: 10px;
            }

            &__link .access__image {
                width: 140px;
                height: 90px;
                margin-bottom: 50px;
            }

            &__content {
                height: 50px;
            }
        }


        .container--flex {
            column-gap: 20px;
        }

        // News (posts)
        .news {
            padding: 70px 0;
            width: 620px;

            .swiper-slide {
                width: 300px;
                margin: 0 20px 0 0;
            }

            .swiper-container {
                margin: -10px;
                padding: 10px;
            }

            &__content {
                height: 175px;
            }

            &__infos {
                height: 240px;
                padding: 20px;
            }
        }


        // Kiosque 
        .kiosque {
            &__image {
                width: 280px;
                height: 414px;
            }

            &__content {
                bottom: 35px;
            }
        }

        // Events 
        .events__item {
            flex: 1 1 46%;
            margin-left: 40px;
        }


        // Zoom (admin-area-2)
        .zoom {

            &__list {
                column-gap: 20px;
            }
            
            &__content {
                margin: 0px 10px;
                padding: 20px;
            }

            &__item {
                width: calc(50% - 10px);
            }
        }

        // Social wall 
        .social-wall {
            #ff-stream-1 .ff-filter:hover, 
            #ff-stream-1 .ff-filter.ff-filter--active, 
            #ff-stream-1 .ff-moderation-button, 
            #ff-stream-1 .ff-loadmore-wrapper .ff-btn, 
            #ff-stream-1 .ff-square:nth-child(1) {
                min-width: 300px;
                margin: 30px 0;
            }

            .social-wall #ff-stream-1 {
                margin: 0 -10px;
            }
        }

    }

}


// 960
@media screen and (max-width: $medium) {

    .home {

        // Style home 
        &__buttons {
            flex-wrap: wrap;
        }

        // Slideshow
        .home-slideshow {
            margin-top: -35px;
            padding-right: 0;
            padding-top: 0;

            .swiper-container {
                height: 500px;
            }

            .slideshow__content {
                justify-content: center;
                align-items: center;
                padding: 30px 0;
                min-height: 100px;

                p {
                    width: 620px;
                    padding-right: 160px;
                    box-sizing: border-box;
                }
            }

            .swiper-container .container--pagination {
                right: calc((100% - 620px) / 2);
                bottom: 25px;
            }

            .video-container {
                height: 500px;

                .slideshow {
                    video {
                        display: none;
                    }
                    img {
                        display: block;
                    }

                    &__content {       
                        p {
                            padding-left: 30px;
                            width: 100%;
                            box-sizing: border-box;
                        }
                    }
        
                }
            }
        }

        // Access (admin-area-1)
        .home__slider-access__container {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
        }
        .access {
            width: 100%;
            margin-right: 0;
            padding: 50px 0;

            &__link .access__image {
                width: 170px;
                height: 108px;
                margin-bottom: 62px;
            }

            &__content {
                height: 62px;
            }

            &__list {
                gap: 20px;
                justify-content: center;

                li {
                    margin: 0;

                    &:first-child, &:nth-child(4) {
                        margin-left: 0;
                    }

                    &:nth-child(3), &:nth-child(6) {
                        margin-right: 0;
                    }
                }
            }
        }


        // News (posts)
        .news {
            padding: 50px 0;
            width: calc(100% - 320px);
            min-width: calc(50% - 10px);

            .swiper-slide {
                // margin: 0;
                width: 100%;
            }

            &__navigation {
                width: 100px;

                &--slide {
                    display: flex;
                }
            }
        }

        // Kiosque 
        .kiosque {
            padding: 50px 0;
            width: calc(50% - 10px);
            max-width: 300px;

            &__image {
                height: 414px;
                width: calc(100% - 20px);
            }

            &__content {
                width: calc(100% - 30px);
            }
        }

        // Events 
        .events__item {
            flex: 1 1 100%;
            margin-left: 20px;
            margin-bottom: 20px;

            &:nth-child(3) {
                margin-bottom: 20px;
            }
        }

        // Zoom (admin-area-2)
        .zoom {
            &__list {
                justify-content: center;
                flex-wrap: wrap;
                gap: 30px 20px;
            }

            &__item {
                flex: inherit;
            }
        }

    }

}


// 640
@media screen and (max-width: $small) {

    .home {

        // Style home 
        .container--flex {
            flex-wrap: wrap;
        }

        &__title {
            text-align: center;
        }


        // Slideshow
        .home-slideshow {
            .swiper-container, .video-container {
                height: 415px;
            }

            .video-container .slideshow__content {
                padding: 20px;
            } 

            .slideshow__content {
                text-align: center;
                padding: 30px 0 70px 0;
    
                p {
                    padding: 0 15px;
                    width: 100%;
                }
            } 

            .swiper-container .container--pagination {
                right: 0;
                left: 0;
                justify-content: center;
                width: 100%;
                height: auto;
                bottom: 15px;

                .swiper-button {
                    margin: 0 10px;
                }
            }

            .swiper-button-prev, .swiper-button-next {
                position: relative;
                left: auto;
                right: auto;
            }
        }

        // Access (admin-area-1)
        .access {

            &__link .access__image {
                width: 140px;
                height: 90px;
                margin-bottom: 50px;
            }

            &__content {
                height: 50px;
            }
        }

        // News (posts)
        .news {
            width: 100%;
            margin-right: 0;

            &__title {
                margin-bottom: 70px;

                &--no-slide {
                    margin-bottom: 30px;
                }
            }

            &__navigation {
                top: 55px;
                justify-content: center;
                width: 100%;
                height: auto;
    
                .swiper-button {
                    position: relative;
                    margin: 0 10px;
                }
            }

            .swiper-slide {
                width: 100%;
            }

            &__buttons {
                justify-content: center;
            }
        }

        // Kiosque 
        .kiosque {
            width: 100%;
            max-width: inherit;
            
            &__image {
                height: 414px;
                width: 280px;
            }

            &__content {
                width: 270px;
            }
        }


        // Events 
        .events {
            &__info {
                padding: 10px 10px 10px 15px;

                &__title {
                    font-size: 1.125rem;
                }
            }
        }


        // Zoom (admin-area-2)
        .zoom__item {
            margin: 0 0 20px;
            width: 100%;
        }


        // Social wall 
        .social-wall {
            #ff-stream-1 .ff-filter:hover, 
            #ff-stream-1 .ff-filter.ff-filter--active, 
            #ff-stream-1 .ff-moderation-button, 
            #ff-stream-1 .ff-loadmore-wrapper .ff-btn, 
            #ff-stream-1 .ff-square:nth-child(1) {
                min-width: 300px;
            }
        }
    }

}
