header {
    position: relative;
}

.header-top {
    background-color: $color-bg--transparent;

    .header__identity {
        display: flex;
        align-items: center;
        justify-content: center;

        .identity {
            display: block;
        }

        &__title {
            display: block;
            margin: 0;
            padding: 34px 0;
            font-family: $font-family--identity;
            font-weight: $font-weight--heading;
            font-size: 2.813rem;
            color: $color-text;
            text-align: center;

            &:hover {
                color: $color-text;
            }
        }
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link {
    display: none;
}

.tools {
    position: relative;
    display: block;

    &__content {
        position: relative;
        display: flex;
        justify-content: center;
    }

    &__search {
        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }

    .tool {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 50px;
        height: 50px;
        margin: 10px;
        padding: 10px 5px;
        background-color: $color-main;
        border: 0;
        font-size: $font-size--text-small;
        font-weight: $font-weight-bold;
        color: $color-second;
        text-decoration: none;
        text-transform: uppercase;
        transition: background-color $duration;
        
        svg {
            width: 35px;
            height: 35px;
            margin: 0;
            fill: $color-second;
            transition: all $duration;
        }

        &:hover, &:focus {
            cursor: pointer;
            background-color: $color-dark;
            transition: background-color $duration;

            svg {
                fill: $color-white;
                transition: all $duration;
            }
    
        }

        &:last-child {
            margin-right: 0;
        }

    }

    &__home {
        display: none;
        align-items: center;
        justify-content: center;
        padding: 0px 12px;
        background-color: $color-main;
        width: 0;
        opacity: 0;
        transition: opacity ease-in-out $duration;

        svg {
            width: 30px;
            height: 30px;
            fill: $color-second;
        }

        &:hover, &:focus {
            background-color: $color-dark;

            svg {
                fill: $color-white;
            }
        }
    }

}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        // #google_translate_element {
        //     display: none;
        // }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: all $duration;

                        &:hover {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

.tools__translate {
    position: relative;

    &__wrapper {
        @include absolute($left: 50%, $top: 65px);
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        width: max-content;
        padding: 15px 20px 30px;
        background-color: $color-white;
        border-radius: $border-radius;
        box-shadow: $shadow;
        transform: translateX(-50%);
        z-index: 17;
        //filter: drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.08)) drop-shadow(1px 1px 10px rgba(27, 35, 54, 0.08));
    
        &.js-open {
            display: flex;
        }

        &::before {
            content: '';
            @include absolute($top: -6px, $left: 50%);
            display: block;
            @include size(30px);
            background-color: $color-white;
            border-radius: 5px;
            transform: translateX(-50%) rotate(45deg);
            pointer-events: none;
            z-index: -1;
        }

        a.glink {
            position: relative;
            font-family: $font-family;
            font-size: $font-size--text;
            font-weight: $font-weight;
    
            &:hover, &:focus {
                text-decoration: underline;
            }
    
            &.gt-current-lang {
                font-weight: $font-weight-bold;
                text-decoration: underline;
            }
        }
    }

    &__close {
        @include absolute($bottom: -10px, $left: 50%);
        @include flex($alignItems: center, $justifyContent: center);
        transform: translateX(-50%);
        padding: 0;
        margin: 0;
        background-color: $color-main;
        border: 0;
        border-radius: $border-radius--round;
        transition: background-color $duration $timing;

        svg {
            @include size(26px);
            margin: 0;
            fill: $color-second;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $color-dark;

            svg {
                fill: $color-white;
            }
        }
    }
}

// iframe.goog-te-menu-frame.skiptranslate {
//     position: fixed;
//     top: 122px!important;
//     left: calc(50% + 305px)!important;
// }


//======================================================================================================
// Header Fixed
//======================================================================================================
.header.fixed {
    .tools__search {
        position: fixed;
        top: 0;
        right: calc((100% - 1170px) / 2);
        z-index: 99;
        width: 53px;
        height: 78px;
        margin: 0;
    }

    .header-fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 98;
        filter: drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.15)) drop-shadow(1px 1px 10px rgba(27, 35, 54, 0.15));
    
        .tools__home {
            display: flex;
            width: 54px;
            height: 78px;
            opacity: 1;
            transition: all ease-in-out $duration;
        }

        #nav-main {
            .menu__nav__list {
                padding-right: 53px;
            }
            .nav-main-item {
                .nav-main-link {
                    padding: 16px 45px;
                    font-weight: $font-weight-bold;
                }

                &:nth-child(2) {
                    .nav-main-link::after {
                        display: none;
                    }
                }
            }

            .container--nav:after {
                right: -100%;
                transition: all ease-in-out $duration;
            }
        }
    }
}

.admin-bar .header.fixed {
    .tools__search, .header-fixed {
        top: 32px;
    }
} 

//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-dark--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    // iframe.goog-te-menu-frame.skiptranslate {
    //     position: absolute;
    //     top: 92px!important;
    //     left: calc(50% + 190px)!important;
    // }

    .header.fixed {
        .tools__search {
            right: calc((100% - 940px) / 2);
        }

        .header-fixed #nav-main {
            .menu__nav__list {
                width: 100%;
                padding-right: 160px;
                box-sizing: border-box;
            }

            .nav-main-item {
                .nav-main-link {
                    padding: 16px 38px;
                }
    
                &:last-child .nav-main-link {
                    margin-right: 0;
                }
            }
        }  
    }

    .header-top {    
        .header__identity {    
            &__title {
                font-size: 2.5rem;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .header-top {
        .header__identity {
            justify-content: flex-start;
        }

        .container {
            flex-wrap: wrap;
        }

        .header__identity {
            width: 100%;
        }
    }

    .tools {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: -70px;
        z-index: 14;
        pointer-events: none;

        &__content {
            pointer-events: auto;
        }

        .tool {
            span {
                display: none;
            }
    
            svg {
                width: 40px;
                height: 40px;
                margin: 0;
            }
        }
    }

    // iframe.goog-te-menu-frame.skiptranslate {
    //     position: absolute;
    //     left: calc(60% - 53px)!important;
    //     top: 190px!important;
    // }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    .header-top {
        .header__identity {
            justify-content: center;

            &__title {
                font-size: 2.500rem; //40px
                padding: 20px 0;
            }
        }
    }

    .tools {
        justify-content: center;
        margin-bottom: 20px;
        z-index: inherit;
    }

    // iframe.goog-te-menu-frame.skiptranslate {
    //     position: absolute!important;
    //     left: 140px!important;
    //     top: 165px!important;
    // }

    .tools-view {
        .container--tools-view {
            .profils {
                margin-top: 80px;
                margin-right: 35px;
            }
        }
    }

}
