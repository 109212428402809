.scrollTopBtn {
    position: fixed;
    z-index: 100;
    bottom: 20px;
    right: 20px;
    width: 45px;
    height: 45px;
    border-radius: $border-radius;
    border: none;
    margin: 0;
    padding: 0;
    opacity: 0;
    transform: translateY(100px);
    transition: all $duration ease;

    &--show,
    &--show:focus  {
        opacity: 1;
        transform: translateY(0);
        background: $color-main;
    }

    svg {
        margin-right: 0;
        transition: none;

        path { 
            fill: $color-second;
            transition: all $duration ease;
        }
    } 

    &:hover,
    &:focus {
        background: $color-dark;

        svg {
            transition: none;
            
            path {
                fill: $color-white;
                transition: all $duration ease;
            }
        } 
    }
  }