//=================================================================================================
// Clearfix
//=================================================================================================

// Usage : @extend %clearfix;
%clearfix {
    *zoom: 1;

    &:before, &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}


//=================================================================================================
// Visually hide an element
//=================================================================================================

//Usage : @extend %visuallyhidden
%visuallyhidden {
    width: 1px;
    height: 1px;
    position: absolute;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    margin: -1px;
    white-space: nowrap;
}


//=================================================================================================
// Centered block
//=================================================================================================

// Usage : @extend %center
%center {
    display: block;
    margin-left: auto;
    margin-right: auto; 
}


//=================================================================================================
// Centered container
//=================================================================================================

//Usage : @extend %container
%container {
    max-width: $large;
    padding: 0 ($gl-gutter * 0.5);
    margin: 0 auto;
}


//=================================================================================================
// Cacul for positionning element left or right container
//=================================================================================================

// Usage : @extend %SpaceFull-to-Container--left
%SpaceFull-to-Container--left {
    left: calc((100% - ($large - $gl-gutter))/2);
}

// Usage : @extend %SpaceFull-to-Container--right
%SpaceFull-to-Container--right {
    right: calc((100% - ($large - $gl-gutter))/2);
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    %container {
        max-width: $medium;
        padding: 0 ($gl-gutter-md * 0.5);
        margin: 0 auto;
    }

    %SpaceFull-to-Container--left {
        left: calc((100% - ($medium - $gl-gutter-md))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - ($medium - $gl-gutter-md))/2);
    }
}


// 960
@media screen and (max-width: $medium) {

    %container {
        max-width: 90%;
        padding: 0 ($gl-gutter-sm * 0.5);
        margin: 0 auto;
    }

    %SpaceFull-to-Container--left {
        left: calc((100% - ($small - $gl-gutter-sm))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - ($small - $gl-gutter-sm))/2);
    }
}


// 640
@media screen and (max-width: $small) {

    %container {
        padding: 0 ($gl-gutter-xs * 0.5);
        margin: 0 auto;
    }
    
    %SpaceFull-to-Container--left {
        left: calc((100% - ($x-small - $gl-gutter-xs))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - ($x-small - $gl-gutter-xs))/2);
    }
}
