//======================================================================================================
// Bloc Onglets
//======================================================================================================
.bloc-tabs {
    position: relative;
    margin: 30px 0;

    &__list {
        @include flex($wrap: wrap);
    }

    &__tab {
        position: relative;
        z-index: 1;
        @include flex($justifyContent: center, $alignItems: center);
        height: 40px;
        margin: 11px 10px (-$btn-border-width) 0px;
        padding: 0 20px;
        background-color: $color-white;
        border: $btn-border-width $border-style $color-dark;
        border-radius: $border-radius $border-radius 0 0;
        font-weight: $font-weight-medium;
        line-height: 1rem;
        color: $color-text;
        cursor: pointer;

        &--active,
        &:hover,
        &:focus {
            background-color: $color-main;
            border-color: $color-dark;
            color: $color-second;
            transition: {
                property: background-color, border-color, color;
                duration: $duration;
                timing-function: $timing;
            }
        }
    }

    &__panel {
        position: relative;
    }

    &__content {
        position: absolute;
        z-index: 2;
        opacity: 0;
        width: 100%;
        padding: 30px;
        background-color: $color-white;
        border: $btn-border-width $border-style $color-dark;
        border-radius: $border-radius;
        z-index: -1;

        p, a, ul, ol {
            display: none;
        }

        &--active {
            animation: fadeTab 0.5s forwards;
            z-index: 3;

            p, a, ul, ol {
                display: block;
            }
        }
    }
}

/* Animation Tab */
@keyframes fadeTab {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
