//==============================================================================
// Abstracts
//==============================================================================
@import "abstracts/mixins";
@import "abstracts/variables";
@import "abstracts/placeholders";


//==============================================================================
// Base
//==============================================================================
@import "base/font-face";
@import "base/helpers";
@import "base/normalize";
@import "base/print";
@import "base/reset";
@import "base/scaffolding";


//==============================================================================
// Components
//==============================================================================
@import "components/buttons";
@import "components/cards";
@import "components/datepicker";
@import "components/menu";
@import "components/pagination";
@import "components/flexibles";
@import "components/see-more";
@import "components/breadcrumb";
@import "components/actions-post";
@import "components/post-navigation";
@import "components/accordion";
@import "components/proposition";
@import "components/document";
@import "components/search";
@import "components/scroll-top";
@import "components/dropdown-list";
//@import "components/favorites"; // Feature actuellement sur Extranet V2 (décommenter si besoin)

    //==============================================================================
    // Flexibles components 
    //==============================================================================
    @import "components/flexibles/f-content";
    @import "components/flexibles/f-album";
    @import "components/flexibles/f-contextual-publications";
    @import "components/flexibles/f-chronology";
    @import "components/flexibles/f-embed-code";
    @import "components/flexibles/f-numbers";
    @import "components/flexibles/f-portraits";
    @import "components/flexibles/f-image";
    @import "components/flexibles/f-map";
    @import "components/flexibles/f-columns";
    @import "components/flexibles/f-tabs";
    @import "components/flexibles/f-countdown";
    @import "components/flexibles/f-table";



//==============================================================================
// Layout
//==============================================================================
@import "layout/filter";
@import "layout/footer";
@import "layout/forms";
@import "layout/grid";
@import "layout/header";
@import "layout/layout";
@import "layout/listing-cards";
@import "layout/tables";
@import "layout/top-publications";
@import "layout/listing";
@import "layout/404";


//==============================================================================
// Pages
//==============================================================================
@import "pages/home";
@import "pages/single";
@import "pages/documents";
@import "pages/transition";
@import "pages/maps";


//==============================================================================
// Plugins
//==============================================================================
@import "plugins/accessconfig/accessconfig";

/* @import "plugins/chatbot/chatbot-variables";
@import "plugins/chatbot/chatbot"; */

@import "plugins/comarquage/co-variables";
@import "plugins/comarquage/co-icons";
@import "plugins/comarquage/co-base";
@import "plugins/comarquage/co-style";
@import "plugins/comarquage/co-header";
@import "plugins/comarquage/co-archive";
@import "plugins/comarquage/co-single";
@import "plugins/comarquage/co-flexibles";

/* @import "plugins/consultations/consultations-variables";
@import "plugins/consultations/consultations";
@import "plugins/consultations/consultations-cards";
 */
@import "plugins/contacts/base";
@import "plugins/contacts/v1/contact-details";
@import "plugins/contacts/v2/contact-details";
@import "plugins/contacts/v3/contact-article";

// Documents
@import "plugins/documents/documents-button";
@import "plugins/documents/documents-details";
@import "plugins/documents/documents-card";
@import "plugins/documents/documents-category";

@import "plugins/events/v2";

/* @import "plugins/franceconnect/franceconnect"; */

@import "plugins/infowindow/infowindow";

/* @import "plugins/jobs/jobs"; */

@import "plugins/newsletters/newsletters-form";

@import "plugins/payfip/payfip";

/* @import "plugins/reports/reports"; */

/* @import "plugins/sms-alerts/sms-alerts-template"; */

/* @import "plugins/useful-page/useful-page"; */


//==============================================================================
// Themes
//==============================================================================


//==============================================================================
// Vendors
//==============================================================================

// Swiper
@import '../../node_modules/swiper/swiper';
// Style Swiper pour l'accessibilite (aria-label + tabindex)
@import '../../node_modules/swiper/components/a11y/a11y';
// Style Swiper pour l'effet Coverflow
@import '../../node_modules/swiper/components/effect-coverflow/effect-coverflow';
// Style Swiper pour l'effet Fade
@import '../../node_modules/swiper/components/effect-fade/effect-fade';
// Style Swiper pour l'effet Flip
@import '../../node_modules/swiper/components/effect-flip/effect-flip';
// Style Swiper pour le chargement progressif des images
@import '../../node_modules/swiper/components/lazy/lazy';
// Style Swiper pour les fleches de navigation
@import '../../node_modules/swiper/components/navigation/navigation';
// Style Swiper pour la pagination (bullet, progress, fraction)
@import '../../node_modules/swiper/components/pagination/pagination';
// Style Swiper pour une pagination avec miniatures
@import '../../node_modules/swiper/components/thumbs/thumbs';


// Glightbox
@import '../../node_modules/glightbox/dist/css/glightbox.min.css';
