.cover {
    position: relative;
    background-color: $color-bg--neutral;

    &__content {
        display: flex;
        align-items: stretch;
        margin-top: -38px;
    }

    &__image-wrapper {
        width: 50%;
        height: auto;
        min-height: 400px;
        padding-top: 38px;
        background-color: $color-bg--image;
        flex: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

        + .cover {
            &__informations {
                padding: 50px calc((100% - 1170px) / 2) 50px 50px;
            }
        }
    }

    &__informations {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        padding: 70px 15px 50px;
        max-width: 1200px;
        color: $color-text; 
        margin: 0 auto;      
    }

    &__title {
        margin: 0 0 10px;
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight;
            font-size: 1.125rem;
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        &__image-wrapper {
            + .cover {
                &__informations {
                    padding: 50px calc((100% - 940px) / 2) 50px 50px;  
                }
            }
        }

        &__informations {
            padding: 70px 10px 50px;  
            max-width: 960px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .cover {
        &__content {
            flex-wrap: wrap;
        }

        &__image-wrapper {
            width: 100%;
            min-height: 380px;
            padding-top: 0;
            margin-top: 0;

            + .cover {
                &__informations {
                    padding: 50px;
                }
            }
        }

        &__informations {
            width: 100%;
            padding: 90px 0 50px;
            align-items: flex-start;
        }

        &__title, &__lead-wrapper, &__intro, &__category {
            width: 100%;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 255px;
        }

        &__title, &__lead-wrapper, &__intro, &__category {
            width: 100%;
        }
    }

}
