.footer {
    position: relative;
    overflow: hidden;

    .container {
        display: flex;
    }

    &__title {
        margin: 0 0 15px;
        font-family: $font-family--heading;
        font-size: $font-size--4;
        font-weight: $font-weight--heading;
        color: $color-text;
    }

    &__infos {
        padding: 60px 0;
        background-color: $color-white;
        color: $color-text;

        .container {
            display: flex;
            align-content: stretch;
            justify-content: space-between;
        }
    }

    &__coordonnees {
        width: 330px;

        svg {
            width: 26px;
            height: 26px;
            margin-right: 5px;
        }
    }

    &__phone {
        position: relative;
        display: flex;
        align-items: center;
        width: -moz-min-content;    /* Firefox */
        width: -webkit-min-content; /* Chrome */
        width: fit-content;
        padding: 5px 0;
        font-weight: $font-weight-bold;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px;
            background-color: $color-main;
            transition: width ease-in-out $duration;
        }

        &:hover, &:focus {
            color: $color-text;

            &::after {
                width: 100%;
                transition: width ease-in-out $duration;
            }
        }
    }

    &__button {
        width: 100%;
        margin-top: 18px;
        padding: 5px 30px;
        background-color: $color-main;
        border-color: $color-main;
        color: $color-second;

        svg {
            fill: $color-second;
        }

        &:hover, &:focus {
            background-color: $color-dark;
            border-color: $color-dark;
            color: $color-white; 

            svg {
                fill: $color-white;
            }
        }
    }

    &__horaires {
        width: 470px;
    }

    &__rs {
        display: flex;
        align-items: center;
        margin-top: 30px;

        li {
            margin: 0 10px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &__link {
            display: block;
            background-color: $color-main;
            border-radius: $border-radius;
            line-height: 0;

            svg {
                width: 40px;
                height: 40px;
                fill: $color-second;
            }
           
            &:hover, &:focus {
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    &__labels {
        position: relative;
        z-index: 1;
        margin-bottom: -38px;

        .container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            position: relative;
            color: $color-text;

            &::after {
                content: '';
                display: block;
                position: absolute;
                right: -100%;
                left: 15px;
                z-index: -1;
                height: 100%;
                background-color: $color-main;
                box-shadow: $shadow;
            }
        }
    }

    &__link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 0;
        padding: 25px;
        font-size: $font-size--text-small;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $color-text;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: -1.5px;
            width: 3px;
            height: 35%;
            background-color: $color-main;
            border-radius: 50px;
            transform: translateY(-50%);
        }

        &:last-child {
            &::after {
                display: none;
            }
        }

        svg {
            width: 26px;
            height: 26px;
            margin-right: 10px;
            fill: $color-text;
        }

        &:hover, &:focus {
            text-decoration: underline;
            color: $color-text;
        }
    }

    &__menu {
        padding-top: 40px;
        background-color: $color-dark;

        &__list  {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        &__item {
            &:last-child .footer__menu__link {
                &::after {
                    display: none;
                }
            }
        }

        &__link {
            position: relative;
            display: block;
            padding: 20px 45px;
            font-size: $font-size--text-small;
            color: $color-white;
            text-align: center;

            &:hover, &:focus {
                color: $color-white;
                text-decoration: underline;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 25%;
                background-color: $color-white;
                border-radius: 50px;
                transform: translateY(-50%);
                opacity: 1;
                transition: opacity $duration ease-in-out;
            }
        }
    }
}


  
  
//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .footer {

        &__button {
            margin-top: 40px;
        }

        &__coordonnees {
            width: 260px;
        }

        &__horaires {
            width: 380px;
        }

        &__title {
            font-size: $font-size--text-medium;
        }

        &__menu__link {
            padding: 20px 25px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .footer {
        &__infos {
            .container {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
        
        &__logo, &__coordonnees {
            margin-bottom: 30px;
        }

        &__logo, &__coordonnees, &__horaires {
            text-align: center;
        }

        &__title {
            margin-bottom: 5px;
        }

        &__phone {
            justify-content: center;
            width: 100%;
            margin-top: 5px;

            &::after {
                display: none;
            }
        }      

        &__button {
            margin: 25px 0 0 0;
        }

        &__rs {
            justify-content: center;
        }

        &__labels {
            .container {
                flex-wrap: wrap;
            }
        }

        &__link {
            flex: 1 1 50%;
            
            &:nth-child(2) {
                &::after {
                    display: none;
                }
            }
        }

        &__menu {
            &__list {
                padding-bottom: 15px;
            }

            &__item {
                padding: 15px 0 0 0;

                &:first-child {
                    .footer__menu__link {
                        padding-left: 0;
                    }
                    
                }

                &:last-child {
                    .footer__menu__link {
                        padding-right: 0;
                    }
                }
            }

            &__link {
                padding: 0 30px;
                
                &::after {
                    height: 60%;
                }
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .footer {

        &__labels {
            .container {
                flex-wrap: wrap;
            }
        }

        &__link {
            flex: 1 1 100%;
            width: 100%;

            &::after {
                top: auto;
                bottom: -1.5px;
                right: auto;
                width: 30px;
                height: 3px;
                transform: none;
            }
        }

        &__coordonnees, &__horaires {
            width: 100%;
        }

        &__rs li {
            margin: 0 6px;
        }

        &__menu {
            &__list {
                padding: 5px 0 20px 0;
            }

            // &__item {
            //     &:first-child, &:nth-child(2), &:nth-child(4) {
            //         .footer__menu__link {
            //             &::after {
            //                 display: none;
            //             }
            //         }
            //     }
            // }

            &__link {
                padding: 0 10px;

                &::after {
                    height: 50%;
                }
            }
        }
    }

}